import styled from 'styled-components'
import { Breakpoints, Colors, Fonts } from 'src/config/theme'

const Brand = styled.div((props) => {
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
})

const Logo = styled.img((props) => {
  return {
    width: '350px',
    height: '300px',
    [`@media(max-width: ${Breakpoints.medium})`]: {
      width: "287px",
      height: '240px'
    }
  }
})

const Content = styled.div((props) => {
  return {
    textAlign: 'center'
  }
})

const Name = styled.h1((props) => {
  return {
    ...Fonts.display.large,
    [`@media(max-width: ${Breakpoints.large})`]: {
      ...Fonts.display.medium
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      ...Fonts.display.small
    }
  }
})

const Position = styled.h2((props) => {
  return {
    ...Fonts.title.medium,
    color: Colors.textSubdued,
    [`@media(max-width: ${Breakpoints.large})`]: {
      ...Fonts.title.small
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      ...Fonts.title.xSmall
    }
  }
})

const Buttons = styled.div((props) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px',
    paddingTop: '50px'
  }
})

export { Brand, Logo, Content, Name, Position, Buttons }