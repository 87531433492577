import { BaseButton } from 'src/components/Buttons/BaseButton'
import { MenuButton } from 'src/components/Buttons/MenuButton'
import { Layout } from 'src/components/Layout'
import { MenuLink } from 'src/components/Links/MenuLink'
import { ProjectLink } from 'src/components/Links/ProjectLink'
import { Title } from 'src/components/Title'

const Development = () => {
  return (
    <Layout>
     <ProjectLink />
     <MenuLink />
     <Title />
    </Layout>
  )
}

export default Development