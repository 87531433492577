import { Colors, Fonts } from 'src/config/theme'
import styled from 'styled-components'

const Links = styled.div(() => ({
  display: 'flex',
  padding: '40px 0px',
  flexWrap: "wrap",
  gap: '30px'
})) 

const LinkContent = styled.div(() => ({
  flex: '1'
}))

const Category = styled.h2(() => ({
  ...Fonts.title.small,
  color: Colors.textSubdued
}))

const ExternalLink = styled.a(() => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'baseline',
  gap: '7px',
  paddingTop: '10px'
}))

const Text = styled.p(() => ({
  ...Fonts.title.xSmall
}))

export { Links, LinkContent, Category, ExternalLink, Text }