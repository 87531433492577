import { useEffect } from 'react'
import { BaseButton, Layout } from 'src/components'
import { Brand, Logo, Content, Name, Position, Buttons } from './styles'

const IMG = require('../../assets/Logo@2x.png')

const Home = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Brand>
        <Logo src={IMG} />
        <Content>
          <Name>Genevieve Coogan</Name>
          <Position>software enginner</Position>
        </Content>
      </Brand>
      <Buttons>
        <BaseButton to="/work" >Work</BaseButton>
        <BaseButton to="/about" >About</BaseButton>
      </Buttons>
    </Layout>
  )
}

export default Home