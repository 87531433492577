import { useEffect, useState } from 'react'
import { Layout, Title } from 'src/components'
import { Footer } from 'src/components/Footer'
import { Container, MainImage, ProjectInformation, Categories, Category, CategoryTitle, TechStack, Tech, ProjectDescription, AddtionalImage, Image } from './styles'

const Projects = (props) => {
  const [nextProject, setNextProject] = useState(0)
  const { title, stack, products, description, images, category } = props.project
  
  useEffect(() => {
    window.scrollTo(0, 0)

    if (props.currentIndex !== 4) {
      console.log(props.currentIndex)
      return setNextProject(props.currentIndex + 1)
    } 
    setNextProject(0)
  },[props.currentIndex, nextProject])


  return (
    <Layout>
      <Container>
        <MainImage>
          <Image src={images[0].main} />
        </MainImage>
        <Title>{title}</Title>
        <ProjectInformation>
            <Categories>
              <Category>
                <CategoryTitle>Products</CategoryTitle>
                <TechStack>
                  {products.map((product, index) => {
                    return <Tech key={index} >{product}</Tech>
                  })}
                </TechStack>
              </Category>
              <Category>
                <CategoryTitle>Tools</CategoryTitle>
                <TechStack>
                  {stack.map((tech, index) => {
                    return (
                      <Tech key={index}>{tech}</Tech>
                    )
                  })}
                </TechStack>
              </Category>
            </Categories>
            <ProjectDescription>{description}</ProjectDescription>
        </ProjectInformation>
        <AddtionalImage>
          <Image src={images[0].image01} />
        </AddtionalImage>
        <AddtionalImage>
          <Image src={images[0].image02} />
        </AddtionalImage>
      </Container>
      <Footer nextProject={props.projects[nextProject]} name={title} category={category} />
    </Layout>
  )
}

export default Projects