import styled from 'styled-components'
import { Fonts, Colors, Breakpoints } from 'src/config/theme'
import { Link } from 'react-router-dom'

const ProjectContainer = styled.ul(() => ({
  margin: '0',
  padding: '0'
}))

const LinkContainer = styled(Link)(() => ({
  textDecoration: 'none'
}))


const Content = styled.li((props) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0px',
    transition: 'all 200ms ease-out',
    borderBottom: `1px solid ${Colors.textSubdued}`
  }
})

const NameContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
}))

const NameText = styled.h1(() => ({
  margin: '0',
  ...Fonts.title.small,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.title.xSmall
  }
}))

const CategoryText = styled.h1(() => ({
  ...Fonts.body.medium,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.body.small
  }
}))

export { ProjectContainer, LinkContainer, Content, NameContainer, NameText, CategoryText}