import styled from 'styled-components'
import { Fonts, Colors, Breakpoints } from 'src/config/theme'

const Container = styled.div(() => ({
  borderBottom: '2px',
  height: '40px',
  display: 'flex',
  gap: '8px',
  flexDirection: 'row',
  alignItems: 'center',
  flex: '1',
  '&:hover': {
    borderBottom: `solid ${Colors.text}`
  }
}))

const Button  = styled.div(() => ({
  textAlign: 'left',
  padding: '8px 0px',
  transition: 'all 200ms ease-out',
  [`@media(max-width: ${Breakpoints.medium})`]: {
    maxWidth: '90px'
  }
}))


const Text = styled.h1(() => ({
  ...Fonts.title.small,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.title.xSmall
  }
}))

export { Container, Button, Text }