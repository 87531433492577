const Colors = {
  primary: '#000148',
  secondary: '#222222',
  text: '#FFFFFF',
  textSubdued: '#BBBBBB'
}


const displayDefaultStyles = {
  fontFamily: 'Hahmlet',
  fontWeight: '900',
  margin: 0,
  color: Colors.text
}

const titleDefaultStyles = {
  fontFamily: 'Lexend Zetta',
  textTransform: 'uppercase',
  margin: 0,
  color: Colors.text
}

const bodyDefaultStyles = {
  fontFamily: 'Lexend',
  fontWeight: 400,
  margin: 0,
  color: Colors.text,
}

const Fonts = {
  display: {
    large: {
      ...displayDefaultStyles,
      fontSize: '80px',
      lineHeight: '120px',
    },
    medium: {
      ...displayDefaultStyles,
      fontSize: '48px',
      lineHeight: '72px',
    },
    small: {
      ...displayDefaultStyles,
      fontSize: '32px',
      lineHeight: '48px'
    }
  },
  title: {
    xLarge: {
      ...titleDefaultStyles,
      fontSize: '48px',
      fontWeight: 900,
      lineHeight: '72px'
    },
    large: {
      ...titleDefaultStyles,
      fontSize: '32px',
      fontWeight: 900,
      lineHeight: '48px'
    },
    medium: {
      ...titleDefaultStyles,
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px'
    },
    small: {
      ...titleDefaultStyles,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px'
    },
    xSmall: {
      ...titleDefaultStyles,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '18px'
    }
  },
  body: {
    medium: {
      ...bodyDefaultStyles,
      fontSize: '16px',
      lineHeight: '24px'
    },
    small: {
      ...bodyDefaultStyles,
      fontSize: '12px',
      lineHeight: '18px'
    }
  }
}

const Breakpoints = {
  large: '1000px',
  medium: '680px',
  small: '375px'
}

export {
  Colors,
  Fonts,
  Breakpoints
}