import { useState } from 'react'
import ArrowRight from 'src/assets/ArrowRight'
import { ProjectContainer, LinkContainer, Content, NameContainer, NameText, CategoryText } from './styles'

export const ProjectLink = (props) => {
  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  return (
    <ProjectContainer>
      <LinkContainer to={props.to} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Content isHovering={isHovering}>
          <NameContainer>
            <NameText>{props.name}</NameText>
            {isHovering && (
              <ArrowRight width='16' height='12px'  />
            )}
          </NameContainer>
          <CategoryText>{props.category}</CategoryText>
        </Content>
      </LinkContainer>
    </ProjectContainer>
  )
}