import styled from 'styled-components'
import { Colors, Breakpoints } from 'src/config/theme'

const Container = styled.div((props) => {
  return {
    minHeight: '100vh',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.primary
  }
})

const Content = styled.div((props) => ({
    width: "960px",
    padding: '0px 20px',
    flex: '1',
    backgroundColor: Colors.primary,
    [`@media(max-width: ${Breakpoints.large})`]: {
      width: '640px'
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      width: '320px'
    }
}))

export { Container, Content }