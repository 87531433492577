import styled from 'styled-components'
import { Breakpoints, Colors, Fonts } from 'src/config/theme'


const Container = styled.div(() => ({
  height: '133px',
  display: 'flex',
  marginTop: '50px',
  borderTop: `2px solid ${Colors.text}`,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 20px',
  gap: '20px'
}))

const Project = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  flex: '1',
  alignItems: 'end'
}))

const Name = styled.h1(() => ({
  margin: '0',
  textAlign: 'end',
  ...Fonts.title.small,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.title.xSmall
  }
}))

const Category = styled.p(() => ({
  margin: '0',
  ...Fonts.body.small,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.body.xSmall
  }
}))

export { Container, Project, Name, Category  }