import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Development from './Pages/Development'
import Contact from './Pages/Contact'
import Home from './Pages/Home'
import About from './Pages/About'
import Projects from './Pages/Projects'
import Work from './Pages/Work'
import { projectsInfoData } from './config/Data'

const App = () => {

  const projects = projectsInfoData

  return (
    <Router>
      <Routes>
        <Route path="/dev"  element={<Development />}/>
        <Route path="/"  element={<Home />}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work projects={projects} />} />
        {projects.map((project, index) => {
          return <Route key={index} path={`/projects${project.path}`} element={<Projects currentIndex={index} projects={projects} project={project} />} />
        })}
      </Routes>
    </Router>
  )
}

const AppProvider = () => {
  return (
    <App />
  )
}

export default AppProvider