import { useState } from 'react'
import { Menu } from '../Menu'
import { Navbar } from '../Navbar'
import { Container, Content } from './styles'

export const Layout = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    return
  }
  return (
    <Container>
      <Navbar menuOpen={menuOpen} onClick={toggleMenu} />
      {!!menuOpen ? (
        <Menu />
      ) : (
        <>
          <Content>
            {props.children}
          </Content>
        </>
      )}
    </Container>
  )
}