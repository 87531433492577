
import { MenuLink } from '../Links/MenuLink'
import { Container, Links } from './styles'

export const Menu = (props) => {
  
  return (
    <Container>
      <Links>
        <MenuLink to="/" number="01" text="Home" />
        <MenuLink to="/work" number="02" text="Work" />
        <MenuLink to="/about" number="03" text="About" />
        <MenuLink to="/contact" number="04" text="Contact" />
      </Links>
    </Container>
  )
}