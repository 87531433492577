import styled from 'styled-components'
import { Colors } from 'src/config/theme'

const Button = styled.button((props) => ({
  display: 'flex', 
  background: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  border: '2px solid transparent',
  transition: 'transform .4s ease-in-out',
  borderColor: props.isHovering && Colors.textSubdued
}))

const ButtonImage = styled.div((props) => ({
  transition: 'transform .4s ease-in-out',
  transform: props.isHovering && 'rotate(45deg)'
}))

export { Button, ButtonImage }