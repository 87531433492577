import { BaseButton } from '../Buttons/BaseButton'
import { Container, Project, Name, Category } from './styles'

export const Footer = (props) => {
  console.log("proos", props)
  const nextProjectPath = `/projects${props.nextProject.path}`
  return (
    <Container>
      <BaseButton to={nextProjectPath}>Next Project</BaseButton>
      <Project>
        <Name>{props.name}</Name>
        <Category>{props.category}</Category>
      </Project>
    </Container>
  )
}