// const CASL_IMAGES = [{
//   main: {
//     desktop: require('../../assets/casl/main/casl-main.png'),
//     tablet: require('../../assets/casl/main/casl-main-tablet.png'),
//     mobile: require('../../assets/casl/main/casl-main-mobile.png'),
//   },
//   image01: {
//     desktop: require('../../assets/casl/image01/casl-01.png'),
//     tablet: require('../../assets/casl/image01/casl-01-tablet.png'),
//     mobile: require('../../assets/casl/image01/casl-01-mobile.png'),
//   },
//   image02: {
//     desktop: require('../../assets/casl/image02/casl-02.png'),
//     tablet: require('../../assets/casl/image02/casl-02-tablet.png'),
//     mobile: require('../../assets/casl/image02/casl-02-mobile.png'),
//   }
// }]

// const ICON_IMAGES = [{
//   main: {
//     desktop: require('../../assets/icon/main/icon-main.png'),
//     tablet: require('../../assets/icon/main/icon-main-tablet.png'),
//     mobile: require('../../assets/icon/main/icon-main-mobile.png'),
//   },
//   image01: {
//     desktop: require('../../assets/icon/image01/icon-01.png'),
//     tablet: require('../../assets/icon/image01/icon-01-tablet.png'),
//     mobile: require('../../assets/icon/image01/icon-01-mobile.png'),
//   },
//   image02: {
//     desktop: require('../../assets/icon/image02/icon-02.png'),
//     tablet: require('../../assets/icon/image02/icon-02-tablet.png'),
//     mobile: require('../../assets/icon/image02/icon-02-mobile.png'),
//   }
// }]

// const HUMPTY_IMAGES = [{
//   main: {
//     desktop: require('../../assets/casl/main/casl-main.png'),
//     tablet: require('../../assets/casl/main/casl-main-tablet.png'),
//     mobile: require('../../assets/casl/main/casl-main-mobile.png'),
//   },
//   image01: {
//     desktop: require('../../assets/casl/image01/casl-01.png'),
//     tablet: require('../../assets/casl/image01/casl-01-tablet.png'),
//     mobile: require('../../assets/casl/image01/casl-01-mobile.png'),
//   },
//   image02: {
//     desktop: require('../../assets/casl/image02/casl-02.png'),
//     tablet: require('../../assets/casl/image02/casl-02-tablet.png'),
//     mobile: require('../../assets/casl/image02/casl-02-mobile.png'),
//   }
// }]

const CASL_IMAGES = [{
  main: require('../../assets/casl/casl-main.png'),
  image01: require('../../assets/casl/casl-image01.png'),
  image02: require('../../assets/casl/casl-image02.png')
}]

const ICON_IMAGES = [{
  main: require('../../assets/icon/icon-main.png'),
  image01: require('../../assets/icon/icon-image01.png'),
  image02: require('../../assets/icon/icon-image02.png')
}]

const HUMPTYS_IMAGES = [{
  main: require('../../assets/humptys/humptys-main.png'),
  image01: require('../../assets/humptys/humptys-image01.png'),
  image02: require('../../assets/humptys/humptys-image02.png')
}]

const NEP_IMAGES = [{
  main: require('../../assets/nep/nep-main.png'),
  image01: require('../../assets/nep/nep-image01.png'),
  image02: require('../../assets/nep/nep-image02.png')
}]

const MTS_IMAGES = [{
  main: require('../../assets/mts/cengage_main.png'),
  image01: require('../../assets/mts/cengage_image01.png'),
  image02: require('../../assets/mts/cengage_image02.png')
}]



export const projectsInfoData = [
  {
    title: 'Cengage Group',
    category: 'Ed Tech',
    stack: ['React', 'Javascript', 'Typscript'],
    products: ['E-Learning Platform'],
    description: <p>
    I am a dedicated software engineer at Cengage, my role centers around contributing significantly to the MindTap school platform—a dynamic and innovative educational tool.<br/><br/> In my role, I work with a team of engineers to uphold and expand the platform. I have significantly contributed to the platform's evolution by executing key responsibilities. This includes the conversion of JavaScript files to TypeScript, a process that not only enhances the robustness of the codebase but also ensures its long-term maintainability.<br/><br/> Additionally, I successfully transitioned Enzyme tests to Jest and RTL, streamlining the testing processes and fortifying the reliability of our code. Beyond these technical achievements, I have actively driven the platform's growth by working on implementinng new features, addressing bugs, and maintaining the overall codebase. <br/><br/> This multifaceted approach has played a vital role in the ongoing enhancement and stability of the MindTap school platform.</p>,
    path: '/01',
    images: MTS_IMAGES
  },
  {
    title: 'College Ave',
    category: 'Lender',
    stack: ['React', 'React-Native', 'AWS', 'Wordpress'],
    products: ['Website', 'Mobile App', 'Lending Portal'],
    description: <p>As an employee at BrainDo, a digital marketing agency, I had the opportunity to work with a client who had an extensive digital presence. Their online platform included a website, a mobile app, and a lending portal, each requiring regular updates and improvements.<br/><br/> Working alongside a team of skilled developers, we used a range of technologies to build and enhance the platform. On the front-end, we utilized React, React Native, and WordPress to create a user-friendly experience, while on the back-end we employed AWS to ensure reliable and efficient server infrastructure. <br/><br/> Through a collaborative effort, we were able to successfully meet the client's needs and improve the functionality of their digital products.</p>,
    path: '/02',
    images: CASL_IMAGES
  },
  {
    title: 'Icon',
    category: 'Medical',
    stack: ['React', 'React-Native', 'AWS'],
    products: ['Website', 'Mobile App', 'Admin Portal'],
    description: <p>While working at BrainDo, I had the opportunity to work with a team of developers to help build a document-sharing app. To achieve this, we utilized React, React Native, and AWS to build the client a web-facing application, a mobile app, and an administrative web portal. <br/><br/> With React, we created a responsive and user-friendly frontend interface for the application as well as a separate web portal for administrators. React Native was used to create a seamless mobile experience that matched the features of the web version, while AWS provided a reliable backend for storing and retrieving documents. <br/><br/> As a result of our efforts, the client was able to provide a secure and efficient document-sharing experience for their users across all devices.</p>,
    path: '/03',
    images: ICON_IMAGES
  },
  {
    title: 'Humpty Dumplings',
    category: 'Resturant',
    stack: ['React'],
    products: ['Website'],
    description: <p>In the past, I had the opportunity to make a website for a local restaurant called Humpty Dumplings. As a popular restaurant in the area, Humpty Dumplings needed a website that would provide their customers with an easy way to view their menu and learn more about their resturant. I chose to use React to build the wesbite because it allowed me to create a dynamic and responsive user interface.<br/><br/> With React's component-based approach, I was able to create reusable UI elements, which helped me to streamline the development process and reduce the time it took to build the website. <br/><br/> By utilizing React's powerful features, I was able to deliver a website that met the client's needs and provided their customers with a seamless experience.<br/><br/>Link: <a href="https://humpty-dumplings.netlify.app/" target="_blank" style={{ color: '#BBBBBB', textDecoration: 'none' }} rel="noreferrer">Humpty's Dumplings</a></p>,
    path: '/04',
    images: HUMPTYS_IMAGES
  },
  {
    title: 'No Empty Plate',
    category: 'Food',
    stack: ['React', 'Firebase'],
    products: ['Website'],
    description: <p>As a personal project, I created a meal traking app. This app is designed to help users easily track and catogorize their meals.<br/><br/> With the help of React, I was able to create a user-friendly interface that is easy to navigate and understand. Firebase provided the perfect backend solution, allowing for efficient data storage and real-time updates.<br/><br/>Link: <a href="https://meal-app-d380b.web.app/landing" target="_blank" style={{ color: '#BBBBBB', textDecoration: 'none' }} rel="noreferrer">No Empty Plate</a></p>,
    path: '/05',
    images: NEP_IMAGES
  },
]

export const aboutMeData = <p>I’m a Front-End Software Engineer based in sunny Philadelphia, PA. I specialize in React and have a passion for creating engaging and dynamic user interfaces. <br/><br/> Along with web development, I have experience working with React Native, to develop mobile applications, and AWS, for managing backend infrastructure. <br/><br/> I am always eager to learn and incorporate new technologies into my development stack to stay current and provide the best possible solutions.</p>