import { useState } from 'react'
import Menu from 'src/assets/Menu'
import MenuClosed from 'src/assets/MenuClosed'
import { Button, ButtonImage } from './styles'

export const MenuButton = (props) => {
  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  const handleOnClick = (onClick) => {
    if (onClick) onClick()
    return
  }

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Button isHovering={isHovering} onClick={() => handleOnClick(props.onClick)}>
        <ButtonImage isHovering={isHovering}>
          {!!props.isOpen ? (
            <MenuClosed />
          ):(
            <Menu />
          )}
        </ButtonImage>
      </Button>
    </div>
  )
}