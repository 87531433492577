import { useEffect } from 'react'
import { Layout, Title } from 'src/components'
import External from 'src/assets/External'
import { Links, LinkContent, Category, ExternalLink, Text } from './styles'

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Title>Contact</Title>
      <Links>
        <LinkContent>
          <Category>Email</Category>
          <ExternalLink href="mailto:gcoogan1@gmail.com" target="_blank" >
            <Text>googan1@gmail.com</Text>
            <External />
          </ExternalLink>
        </LinkContent>
        <LinkContent>
          <Category>Social</Category>
          <ExternalLink href="https://www.linkedin.com/in/genevieve-coogan-a06067171/" target="_blank" >
            <Text>Linkedin</Text>
            <External />
          </ExternalLink>
        </LinkContent>
      </Links>
    </Layout>
  )
}

export default Contact

