import styled from "styled-components"
import { Breakpoints } from "src/config/theme"


const PROJECTS_DESKTOP = require('../../assets/projects-desktop.png')
const PROJECTS_TABLET = require('../../assets/projects-tablet.png')

const Container = styled.div(props => {
  return {
    display: 'flex',
    gap: '40px',
    [`@media(max-width: ${Breakpoints.large})`]: {
      flexDirection: 'column'
    }
  }
})

const ProjectImage = styled.div((props) => {
  return {
    width: '400px',
    height: '500px',
    borderRadius: '32px',
    backgroundImage: `url(${PROJECTS_DESKTOP})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [`@media(max-width: ${Breakpoints.large})`]: {
      width: '100%',
      height: '240px',
      backgroundImage: `url(${PROJECTS_TABLET})`,
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      display: 'none'
    }
  }
})

const Projects = styled.div(props => {
  return {
    flex: 1
  }
})

const Links = styled.div(props => {
  return {

  }
})



export { Container, ProjectImage, Projects, Links }