import { useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowRight from 'src/assets/ArrowRight'
import { Container, Button, Text } from './styles'

export const BaseButton = props => {
  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  return (
    <Link style={{ textDecoration: 'none' }} to={props.to}>
      <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Button>
          <Text>{props.children}</Text>
        </Button>
        <ArrowRight width='16' height='12' />
      </Container>
    </Link>
  )
}
