import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors } from 'src/config/theme'

export const Button = styled(Link)(() => ({
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  border: '2px solid transparent',
  '&:hover': {
    borderColor: Colors.textSubdued,
  }
}))