import { HomeButton } from '../Buttons/HomeButton'
import { MenuButton } from '../Buttons/MenuButton'

import { Container, Content, Text } from "./styles"

export const Navbar = (props) => {

  return (
    <Container isOpen={props.menuOpen}>
      <HomeButton to="/" />
      {!!props.menuOpen && (
        <Content>
          <Text>Genevieve</Text>
        </Content>
      )}
      <MenuButton onClick={props.onClick} isOpen={props.menuOpen} />
    </Container>
  )
}