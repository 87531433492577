import { useState } from 'react'
import Brand from 'src/assets/Brand'
import BrandFilled from 'src/assets/BrandFilled'
import { Button } from './styles'

export const HomeButton = props => {
  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  return (
    <Button to={props.to}  onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {!!isHovering ? (
         <BrandFilled />
      ):(
        <Brand />
      )}
    </Button>
  )
}