import { Breakpoints, Fonts } from 'src/config/theme'
import styled from 'styled-components'

const PROFILE = require('../../assets/profile.jpeg')

const Container = styled.div((props) => {
  return {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    paddingBottom: '30px '
  }
})

const Content = styled.div((props) => {
  return {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  }
})

const Image = styled.div((props) => {
  return {
    width: '460px',
    height: '400px',
    borderRadius: '32px',
    backgroundImage: `url(${PROFILE})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [`@media(max-width: ${Breakpoints.large})`]: {
      width: '300px'
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      width: '320px'
    }
  }
})

const AboutText = styled.p((props) => {
  return {
    ...Fonts.body.medium
  }
})

const Links = styled.div((props) => {
  return {
    display: 'flex',
    gap: '20px'
  }
})

const LinkContent = styled.a((props) => {
  return {
    textDecoration: 'none',
    display: 'flex',
    gap: '7px',
    alignItems: 'center'
  }
})

const LinkText = styled.div((props) => {
  return {
    ...Fonts.title.xSmall
  }
})


export { Container, Content, Image, AboutText, Links, LinkContent, LinkText }