import styled from 'styled-components'
import { Fonts } from 'src/config/theme'

const Container = styled.div((props) => {
  return {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex', 
    padding: '48px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: props.isOpen ? 'flex-start' : 'center',
    backgroundColor: props.isOpen ? 'black' : 'transparent'
  }
})

const Content = styled.div(() => ({
  flex: 1,
  height: '48px',
  display: 'flex',
  alignItems: 'center'
}))

const Text = styled.h1(() => ({
  ...Fonts.title.xSmall,
  paddingLeft: "10px"
}))

export { Container, Content, Text }