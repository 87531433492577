import { Breakpoints, Colors, Fonts } from 'src/config/theme'
import styled from 'styled-components'


export const Container = styled.div(() => ({
  width: "100%",
  borderBottom: `1px solid ${Colors.textSubdued}`,
  paddingBottom: '10px'
}))

export const Text = styled.div(() => ({
  ...Fonts.title.xLarge,
  [`@media(max-width: ${Breakpoints.medium})`]: {
    ...Fonts.title.medium
  }
}))