import { useEffect } from "react"
import { Layout, ProjectLink, Title } from "src/components"
import { Container, ProjectImage, Projects, Links } from "./styles"

const Work = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <Layout>
      <Container>
        <ProjectImage />
        <Projects>
          <Title>Projects</Title>
          {props.projects.map(project => {
            return (
              <Links>
                <ProjectLink 
                  name={project.title} 
                  category={project.category}
                  to={`/projects${project.path}`}
                />
              </Links>
            )
          })}
        </Projects>
      </Container>  
    </Layout>
  )
}

export default Work