import styled from 'styled-components'
import { Breakpoints } from 'src/config/theme'

const Container = styled.div((props) => {
  return {
    width: '100%',
    minHeight: '100vh', 
    backgroundColor: "black" ,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  }
})

const Links = styled.div((props) => {
  return {
    width: "960px",
    padding: '128px 0px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    [`@media(max-width: ${Breakpoints.large})`]: {
      width: '640px'
    },
    [`@media(max-width: ${Breakpoints.medium})`]: {
      width: '320px'
    }
  }
})

export { Container, Links }