import styled from "styled-components"
import { Breakpoints, Colors, Fonts } from "src/config/theme"

const Container = styled.div((props) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px'
  }
})

const MainImage = styled.div((props) => {
  return {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '32px'
  }
})

const ProjectInformation = styled.div((props) => {
  return {
    display: 'flex',
    gap: '40px',
    [`@media(max-width: ${Breakpoints.medium})`]: {
      flexDirection: 'column'
    }
  }
})

const Categories = styled.div((props) => {
  return {
    flex: '1',
  }
})

const Category = styled.div((props) => {
  return {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: '30px',
    '&:first-child': {
     paddingTop: '0px'
    }
  }
})

const CategoryTitle = styled.h2((props) => {
  return {
    ...Fonts.title.small,
    flex: '2',
  }
})

const TechStack = styled.div((props) => {
  return {
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  }
})

const Tech = styled.h2((props) => {
  return {
    ...Fonts.body.medium,
    flex: '2',
    color: Colors.textSubdued
  }
})

const ProjectDescription = styled.div((props) => {
  return {
    ...Fonts.body.medium,
    flex: '1'
  }
})

const AddtionalImage = styled.div((props) => {
  return {
    width: '100%',
    height: 'auto'
  }
})

const Image = styled.img((props) => {
  return {
    width: '100%',
    height: '100%' 
  }
})

export { Container, MainImage, ProjectInformation, Categories, Category, CategoryTitle, TechStack, Tech, ProjectDescription, AddtionalImage, Image }