import { useState } from 'react'
import ArrowRight from 'src/assets/ArrowRight'
import { LinkContainer, Content, TextWrapper, NumberWrapper, NumberText, Text } from './styles'

export const MenuLink = (props) => {
  const [isHovering, setIsHovered] = useState(false)
  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  return (
    <LinkContainer to={props.to} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Content isHovering={isHovering}>
        <NumberWrapper>
          <NumberText>{props.number}</NumberText>
        </NumberWrapper>
        <TextWrapper>
          <Text>{props.text}</Text>
        </TextWrapper>
        {!!isHovering && (
          <ArrowRight width='24' height='18' />
        )}
      </Content>
    </LinkContainer>
  )
}