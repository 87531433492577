import styled from 'styled-components'
import { Fonts, Colors } from 'src/config/theme'
import { Link } from 'react-router-dom'

const LinkContainer = styled(Link)(() => ({
  textDecoration: 'none'
}))

const Content = styled.div((props) => {
  return {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    transition: 'all 200ms ease-out',
    padding: '10px 0px',
    borderBottom: props.isHovering ? `2px solid ${Colors.text}` : '2px solid transparent'
  }
})

const TextWrapper = styled.div(() => ({
  margin: '0',
  paddingRight: '6px'
}))

const NumberWrapper = styled.div(() => ({
  width: '20px',
  margin: '0',
}))

const NumberText = styled.h1(() => ({
  ...Fonts.title.xSmall,
  color: Colors.textSubdued
}))

const Text = styled.h1(() => ({
  ...Fonts.title.medium
}))

export { LinkContainer, Content, TextWrapper, NumberWrapper, NumberText, Text }