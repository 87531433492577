import { useEffect } from "react"
import ArrowDown from "src/assets/ArrowDown"
import External from "src/assets/External"
import { Layout, Title } from "src/components"
import { aboutMeData } from "src/config/Data"
import { Container, Content, Image, AboutText, Links, LinkContent, LinkText } from './styles'

const PDF = require('../../assets/GenevieveCooganResume.pdf')
const DESCRIPTION = aboutMeData

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Container>
        <Content>
          <Title>About</Title>
          <AboutText>{DESCRIPTION}</AboutText>
          <Links>
            <LinkContent href={PDF} target="_blank" >
              <LinkText>Resume</LinkText>
              <ArrowDown />
            </LinkContent>
            <LinkContent href="https://github.com/gcoogan1" target="_blank" >
              <LinkText>Github</LinkText>
              <External />
            </LinkContent>
          </Links>
        </Content>
        <Image />
      </Container>
    </Layout>
  )
}

export default About